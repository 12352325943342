import React from 'react';
import {Link} from 'react-router-dom';
import './index.css';
import Logo from '../../assets/images/logo.png';

const Navbar = ({info}) => {
  return (
    <nav>
      <div className="ag__nav-wrapper">
        <div className="ag__nav-logo"><a href="/">
          <img src={Logo} alt={'Loading'}/></a></div>
        <input type="radio" name="slider" id="menu-btn"/>
        <input type="radio" name="slider" id="close-btn"/>
        <ul className="ag__nav-links">
          <label htmlFor="close-btn" className="ag__nav-btn ag__nav-close-btn">
            <i className="fa fa-times"></i>
          </label>
          <li>
            <a href="/#services" className="ag__nav-desktop-item">Services</a>
            <input type="checkbox" id="showMega"/>
            <label htmlFor="showMega" className="ag__nav-mobile-item">
              Services
            </label>
            <div className="ag__nav-mega-box">
              <div className="ag__nav-content">
                {
                  info?.services?.map((service, index) => {
                    return (
                      <div key={index} className="ag__nav-row">
                        <header>{service?.title}</header>
                        <ul className="ag__nav-mega-links">
                          {
                            service?.items?.map((item, subindex) => {
                              return (
                                <li key={subindex}>
                                  <Link to={item?.path}>{item?.title}</Link>
                                </li>
                              );
                            })
                          }
                        </ul>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </li>
          <li>
            <a href="/#products" className="ag__nav-desktop-item">Products</a>
            <input type="checkbox" id="showDrop"/>
            <label htmlFor="showDrop" className="ag__nav-mobile-item">
              Products
            </label>
            <ul className="ag__nav-drop-menu">
              {
                info?.products?.map((product, index) => {
                  return (
                    <li key={index}>
                      <Link to={product?.path}>
                        {product?.title}
                      </Link>
                    </li>
                  );
                })
              }
            </ul>
          </li>
          <li>
            <a href="/#about" className="ag__nav-desktop-item">About Us</a>
            <label className="ag__nav-mobile-item">
              About Us
            </label>
          </li>
          <li>
            <a href="/#contact" className="ag__nav-desktop-item">Contact</a>
            <label className="ag__nav-mobile-item">
              Contact
            </label>
          </li>
        </ul>
        <label htmlFor="menu-btn" className="ag__nav-btn ag__nav-menu-btn">
          <i className="fa fa-bars"></i></label>
      </div>
    </nav>
  );
};

export default Navbar;
