import React, {useState} from 'react';
import './index.css';

const Contact = ({info}) => {
  const [message, setMessage] = useState('');

  const handleOnSubmit = async (event) => {
    const name = event.target.name?.value;
    const email = event.target.email?.value;
    const message = event.target.message?.value;
    event.preventDefault();
    setMessage('Sending....');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);
    formData.append('access_key', '59759317-ca00-4437-9874-bd87d4274d70');
    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setMessage('Email sent Successfully');
      event.target.reset();
    } else {
      setMessage(data.message);
    }
  };
  return (
    <div>
      <div className='ag__title-box'>Contact US</div>
      <div className='ag__contactus-separator'>
        <div className='ag__contactus-info-box'>
          <div className='ag__contactus-info-title'>Contact Info</div>
          <div className='ag__contactus-info'>
            {
              info?.contact?.address?.map((addr, index) => {
                return (
                  <div key={index} className='ag__contactus-address-box'>
                    <div className='ag__contactus-country'>
                      {addr?.country}
                    </div>
                    <div className='ag__contactus-address'>
                      <i className={addr?.address?.icon}/> Address
                    </div>
                    <div className='ag__contactus-addressinfo'>
                      {addr?.address?.value}
                    </div>
                    <div className='ag__contactus-phone'>
                      <i className={addr?.phone?.icon}/> Phone
                    </div>
                    <div className='ag__contactus-phoneinfo'>
                      {addr?.phone?.value}
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div className='ag__contactus-email-box'>
            <div className='ag__contactus-email'>
              <i className={info?.contact?.email?.icon}/> Email
            </div>
            <div className='ag__contactus-emailinfo'>
              {info?.contact?.email?.value}
            </div>
          </div>
        </div>
        <div className='ag__contactus-getintouch-box'>
          <div className='ag__contactus-getintouch-title'>Get in touch</div>
          <div className='ag__contactus-git-info'>
              Please fill out the form below to send
              us an email and we will get back to you as soon as possible.
          </div>
          <div className='ag__contactus-form-box'>
            <form onSubmit={handleOnSubmit}>
              <div className='ag__contactus-namemailbox'>
                <div className='ag__contactus-textbox'>
                  <input name='name' type='text' placeholder={'Name'} required/>
                </div>
                <div className='ag__contactus-textbox'>
                  <input name='email' type='text' placeholder={'Email'}
                    required/>
                </div>
              </div>
              <div className='ag__contactus-messagebox'>
                <div className='ag__contactus-textarea'>
                  <textarea name='message' placeholder={'Message...'} required/>
                </div>
              </div>
              <div className='ag__contactus-btn'>
                <button>Send Message</button>
              </div>
            </form>
            <div className='ag__contact-msg'>{message}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
