import React from 'react';
import './index.css';
// COMPONENTS
import {BreadCrumbs} from '../../components';
// Images
import SectionA from '../../assets/images/tam/sectionA.png';
import SectionB from '../../assets/images/tam/sectionB.png';
import SectionC from '../../assets/images/tam/sectionC.png';
import SectionD from '../../assets/images/tam/sectionD.png';
import Ash2 from '../../assets/images/Ash2.jpeg';
// Lazy Loading
import Lazyloading from '../../components/lazyLoading';

const TAM = ({info}) => {
  // GLOBAL
  const items = [
    {title: 'Home', path: '/'},
    {title: 'Products', path: '/#products'},
    {title: 'Template Manager'},
  ];
  const images = {
    'sectionA': SectionA,
    'sectionB': SectionB,
    'sectionC': SectionC,
    'sectionD': SectionD,
  };
  return (
    <div className=''>
      <BreadCrumbs items={items}/>
      <div className='ag__products-title'>{info?.title}</div>
      {/** SECTION A */}
      <div className='ag__products-separator'>
        <div className='ag__product-info-box'>
          <div className='ag__product-infotitle'>{info?.sectionA?.valueA}</div>
        </div>
        <div className='ag__product-image-box'>
          <Lazyloading
            src={images[info?.sectionA?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION B */}
      <div className='ag__products-separator'>
        <div className='ag__product-info-box'>
          <div className='ag__product-infotitle'>
            {info?.sectionB?.title}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionB?.valueA}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionB?.valueB}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionB?.valueC}
          </div>
        </div>
        <div className='ag__product-image-box'>
          <Lazyloading
            src={images[info?.sectionB?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION C */}
      <div className='ag__products-separator'>
        <div className='ag__product-info-box'>
          <div className='ag__product-infotitle'>
            {info?.sectionC?.title}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionC?.valueA}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionC?.valueB}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionC?.valueC}
          </div>
        </div>
        <div className='ag__product-image-box'>
          <Lazyloading
            src={images[info?.sectionC?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION D */}
      <div className='ag__products-separator'>
        <div className='ag__product-info-box'>
          <div className='ag__product-infotitle'>
            {info?.sectionD?.title}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionD?.valueA}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionD?.valueB}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionD?.valueB}
          </div>
        </div>
        <div className='ag__product-image-box'>
          <Lazyloading
            src={images[info?.sectionD?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
    </div>
  );
};

export default TAM;

