import React from 'react';
import UnderConstruction from '../../assets/images/underconstruction.png';
import Lazyloading from '../../components/lazyLoading';
import Ash2 from '../../assets/images/Ash2.jpeg';

const TestAutomation = () => {
  return (
    <div className='ag__testautomation-container'>
      <div className='ag__construction-box'>
        <Lazyloading
          src={UnderConstruction}
          alt={'Loading...'}
          blurSrc={Ash2}
        />
      </div>
    </div>
  );
};

export default TestAutomation;
