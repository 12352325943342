import React, {useEffect, useState} from 'react';
import './index.css';
import {useLocation} from 'react-router-dom';
import productInfo from '../../data/products.json';
import QRM from './QRM';
import TAM from './TAM';
import TM from './TM';
import LM from './LM';

const Products = () => {
  // GLOBAL
  const location = useLocation();
  // USESTATE
  const [product, setProduct] = useState('');
  // USEEFFECT
  useEffect(() => {
    const path = location?.pathname?.split('/');
    const _product = path[path?.length - 1];
    setProduct(_product);
  }, [location]);
  // RENDER
  return (
    <div className='ag__products-main-container'>
      {product === 'qrm' && <QRM info={productInfo?.qrm}/>}
      {product === 'tam' && <TAM info={productInfo?.tam}/>}
      {product === 'tm' && <TM info={productInfo?.tm}/>}
      {product === 'lm' && <LM info={productInfo?.qrm}/>}
    </div>
  );
};

export default Products;
