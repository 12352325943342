import React from 'react';
import UnderConstruction from '../../assets/images/underconstruction.png';
import Ash2 from '../../assets/images/Ash2.jpeg';
import Lazyloading from '../../components/lazyLoading';


const WorkflowAutomation = () => {
  return (
    <div className='ag__workflow-container'>
      <div className='ag__construction-box'>
        <Lazyloading
          src={UnderConstruction}
          alt={'Loading...'}
          blurSrc={Ash2}
        />
      </div>
    </div>
  );
};

export default WorkflowAutomation;
