import React from 'react';
import {Routes, Route} from 'react-router-dom';

// Pages
import {
  Home,
  Services,
  Products,
} from './pages';

const Router = ({info}) => {
  return (
    <Routes>
      <Route path='/' element={<Home info={info} />}/>
      <Route path='/home' element={<Home info={info} />}/>
      <Route path='/services/*' element={<Services info={info} />}/>
      <Route path='/products/*' element={<Products info={info} />}/>
    </Routes>
  );
};

export default Router;
