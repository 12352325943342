import React, {useEffect, useState} from 'react';
import './index.css';

const Lazyloading = ({src, alt, blurSrc}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
  }, [src]);

  return (
    <div
      className={`blurred-img ${loaded ? 'loaded' : ''}`}
      style={!loaded ? {backgroundImage: `url(${blurSrc})`} : {}}
    >
      <img src={src} alt={alt} loading="lazy" />
    </div>
  );
};

export default Lazyloading;
