import React from 'react';
import './index.css';

const Footer = () => {
  return (
    <div className='ag__footer-container'>
      <div className='ag__footer-content'>
        &copy;2024 <a>ACIS Inc</a>.
      </div>
    </div>
  );
};

export default Footer;
