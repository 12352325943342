import React from 'react';
import './index.css';

const BreadCrumbs = ({items}) => {
  return (
    <div className='ag__breadcrums-container'>
      {
        items?.map((item, index) => {
          return (
            <div key={index} className='ag__breadcrums-item'>
              <a href={item?.path}>{item?.title}</a>
            </div>
          );
        })
      }
    </div>
  );
};

export default BreadCrumbs;
