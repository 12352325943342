import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import './index.css';
import Contact from '../contact';
import AboutUsBg from '../../assets/images/about.png';
// Components
import {Carousil} from '../../components';
// Banners
import BannerA from '../../assets/images/banners/bannerA.png';
import BannerB from '../../assets/images/banners/bannerB.png';
// Service Logos
import CloudMigration from '../../assets/images/cloudmigration.png';
import CustomDevelopment from '../../assets/images/customdevelopment.png';
import Consultation from '../../assets/images/atlassianlogo.png';
import LicenseManagement from '../../assets/images/licensemanagement.png';
import SupportAugmentation from '../../assets/images/supportaugmentation.png';
// Product Logos
import QRM from '../../assets/images/qrmlogo.png';
import TAM from '../../assets/images/tamlogo.png';
import TM from '../../assets/images/tmlogo.png';
import LM from '../../assets/images/licensemanagement.png';
// Lazy loading
import Lazyloading from '../../components/lazyLoading';
import Ash2 from '../../assets/images/Ash2.jpeg';

const Home = ({info}) => {
  // GLOBAL
  const banners = [
    {img: BannerA},
    {img: BannerB},
  ];
  const servicelogos = {
    'cloudmigration': CloudMigration,
    'customdevelopment': CustomDevelopment,
    'consultation': Consultation,
    'licensemanagement': LicenseManagement,
    'supportaugmentation': SupportAugmentation,
  };
  const productlogos = {
    'qrm': QRM,
    'tm': TM,
    'lm': LM,
    'tam': TAM,
  };
  const location = useLocation();
  // USEEFFECTS
  useEffect(() => {
    if (location?.hash) {
      window?.document?.querySelector(location?.hash)
          ?.scrollIntoView({behavior: 'smooth', block: 'nearest'});
    }
  }, []);
  return (
    <div className='ag__home-container'>
      {/** LANDING SCREEN */}
      <div className='ag__home-subcontainer'>
        <Carousil items={banners}/>
      </div>
      {/** SERVICES */}
      <div className='ag__services-container' id='services'>
        <div className='ag__title-box'>Services</div>
        <div className='ag__secondary-title'>Atlassian Services</div>
        <div className='ag__services-items'>
          {
            info?.services?.items?.map((item, index) => {
              return (
                <div key={index} className='ag__services-item'>
                  <div className='ag__services-itemlogobox'>
                    <div className='ag__services-itemlogo'>
                      <Lazyloading
                        src={servicelogos[item?.key]}
                        alt={'Loading...'}
                        blurSrc={Ash2}
                      />
                    </div>
                  </div>
                  <div className='ag__services-itemtitle'>{item?.title}</div>
                  <div className='ag__services-iteminfo'>{item?.info}</div>
                  <div className='ag__services-itemservices'>
                    <ul>
                      {
                        item?.items?.map((service, subindex) => {
                          return (
                            <li
                              key={subindex}>
                              {service}
                            </li>);
                        })
                      }
                    </ul>
                  </div>
                  <div className='ag__services-btn'>
                    <a href={item?.path}>View</a></div>
                </div>
              );
            })
          }
        </div>
      </div>
      {/** PRODUCTS */}
      <div className='ag__products-container' id='products'>
        <div className='ag__title-box'>Products</div>
        <div className='ag__products'>
          {
            info?.products?.items?.map((item, index) => {
              return (
                <div key={index} className='ag__product'>
                  <div className='ag__product-logo'>
                    <Lazyloading
                      src={productlogos[item?.key]}
                      alt={'Loading...'}
                      blurSrc={Ash2}
                    />
                  </div>
                  <div className='ag__product-title'>{item?.title}</div>
                  <div className='ag__product-info'>{item?.info}</div>
                  <ul className='ag__product-items'>
                    {
                      item?.items?.map((it, subindex) => {
                        return (
                          <li key={subindex}
                            className='ag__product-item'>{it}</li>
                        );
                      })
                    }
                  </ul>
                  <div className='ag__product-btn-box'>
                    {
                        item?.completed ?
                    <div className='ag__product-btn'>
                      <div className='ag__product-btn-link'>
                        <a href={item.route}>Learn More →</a>
                      </div>
                      <div className='ag__product-btn-link'>
                        <a href={item.link}
                          rel={'noreferrer'}
                          target='_blank'>Try it free</a>
                      </div>
                    </div> :
                    <div className='ag__product-btn'>
                      <div className='ag__product-btn-link-disabled'>
                        <a href={item.route}>Learn More →</a>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
      {/** ABOUT US */}
      <div className='ag__aboutus-container' id='about'>
        <div className='ag__title-box'>About Us</div>
        <div className='ag__about-separater'>
          <div className='ag__aboutus-bg'>
            <Lazyloading
              src={AboutUsBg}
              alt={'Loading...'}
              blurSrc={Ash2}
            />
          </div>
          <div className='ag__aboutus-info-box'>
            <div className='ag__aboutus-info'>
              {info?.about?.infoA}
            </div>
            <div className='ag__aboutus-info'>
              {info?.about?.infoB}
            </div>
            <div className='ag__aboutus-info-link'>
              {info?.about?.infoC}
            </div>
          </div>
        </div>
      </div>
      {/** CONTACT US */}
      <div className='ag__contactus-container' id='contact'>
        <Contact info={info}/>
      </div>
    </div>
  );
};

export default Home;


