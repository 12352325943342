import React from 'react';
import Router from './Router';
import SmoothScroll from 'smooth-scroll';

// Components
import {
  NavBar,
  Footer,
} from './components';

export const smooth = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  offset: '70',
});

// JSON Data
import Info from './data/data.json';

const App = () => {
  return (<div className='ag__main-container'>
    <div className='ag__header'>
      <NavBar info={Info?.nav}/>
    </div>
    <div className='ag__body'>
      <Router info={Info}/>
    </div>
    <div className='ag__footer'>
      <Footer info={Info}/>
    </div>
  </div>);
};

export default App;
