import React from 'react';
import './index.css';
// COMPONENTS
import {BreadCrumbs, Divider} from '../../components';
// Images
import SectionA from '../../assets/images/businesssupport/sectionA.png';
import SectionB from '../../assets/images/businesssupport/sectionB.png';
import SectionC from '../../assets/images/businesssupport/sectionC.png';
import SectionD from '../../assets/images/businesssupport/sectionD.png';
import SectionE from '../../assets/images/businesssupport/sectionE.png';
import Ash2 from '../../assets/images/Ash2.jpeg';
// Lazy loading
import Lazyloading from '../../components/lazyLoading';

const BusinessSupport = ({info}) => {
  // GLOBAL
  const items = [
    {title: 'Home', path: '/'},
    {title: 'Services', path: '/#services'},
    {title: 'Bussiness Support Services'},
  ];
  const images = {
    'sectionA': SectionA,
    'sectionB': SectionB,
    'sectionC': SectionC,
    'sectionD': SectionD,
    'sectionE': SectionE,
  };
  return (
    <div className='ag__consult-container'>
      <BreadCrumbs items={items}/>
      <div className='ag__services-main-title'>{info?.title}</div>
      <div className='ag__services-main-info-box'>{info?.infoA}</div>
      <div className='ag__services-main-info-boxB'>{info?.infoB}</div>
      <Divider/>
      {/** SECTION A */}
      <div className='ag__services-separator'>
        <div className='ag__service-info-box'>
          <div className='ag__service-infotitle'>
            {info?.sectionA?.title}
          </div>
          <div className='ag__service-infomsg'>
            {info?.sectionA?.valueA}
          </div>
          <div className='ag__service-infomsg'>
            {info?.sectionA?.valueB}
          </div>
        </div>
        <div className='ag__service-image-box'>
          <Lazyloading
            src={images[info?.sectionA?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION B */}
      <div className='ag__services-separator'>
        <div className='ag__service-info-box'>
          <div className='ag__service-infotitle'>
            {info?.sectionB?.title}
          </div>
          <div className='ag__service-infomsg'>
            {info?.sectionB?.valueA}
          </div>
          <div className='ag__service-infomsg'>
            {info?.sectionB?.valueB}
          </div>
        </div>
        <div className='ag__service-image-box'>
          <Lazyloading
            src={images[info?.sectionB?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION C */}
      <div className='ag__services-separator'>
        <div className='ag__service-info-box'>
          <div className='ag__service-infotitle'>
            {info?.sectionC?.title}
          </div>
          <div className='ag__service-infomsg'>
            {info?.sectionC?.valueA}
          </div>
        </div>
        <div className='ag__service-image-box'>
          <Lazyloading
            src={images[info?.sectionC?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION D */}
      <div className='ag__services-separator'>
        <div className='ag__service-info-box'>
          <div className='ag__service-infotitle'>
            {info?.sectionD?.title}
          </div>
          <div className='ag__service-infomsg'>
            {info?.sectionD?.valueA}
          </div>
          <div className='ag__service-infomsg'>
            {info?.sectionD?.valueB}
          </div>
        </div>
        <div className='ag__service-image-box'>
          <Lazyloading
            src={images[info?.sectionD?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION E */}
      <div className='ag__services-separator'>
        <div className='ag__service-info-box'>
          <div className='ag__service-infotitle'>
            {info?.sectionE?.title}
          </div>
          <div className='ag__service-infomsg'>
            {info?.sectionE?.valueA}
          </div>
          <div className='ag__service-infomsg'>
            {info?.sectionE?.valueB}
          </div>
        </div>
        <div className='ag__service-image-box'>
          <Lazyloading
            src={images[info?.sectionE?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessSupport;
