import React from 'react';
import './index.css';
// COMPONENTS
import {BreadCrumbs} from '../../components';
// Images
import SectionA from '../../assets/images/qrm/sectionA.png';
import SectionB from '../../assets/images/qrm/sectionB.png';
import SectionC from '../../assets/images/qrm/sectionC.png';
import SectionD from '../../assets/images/qrm/sectionD.png';
import SectionE from '../../assets/images/qrm/sectionE.png';
import SectionF from '../../assets/images/qrm/sectionF.png';
import SectionG from '../../assets/images/qrm/sectionG.png';
import Ash2 from '../../assets/images/Ash2.jpeg';
// Lazy Loading
import Lazyloading from '../../components/lazyLoading';

const QRM = ({info}) => {
  // GLOBAL
  const items = [
    {title: 'Home', path: '/'},
    {title: 'Products', path: '/#products'},
    {title: 'Quality Risk Manager'},
  ];
  const images = {
    'sectionA': SectionA,
    'sectionB': SectionB,
    'sectionC': SectionC,
    'sectionD': SectionD,
    'sectionE': SectionE,
    'sectionF': SectionF,
    'sectionG': SectionG,
  };
  return (
    <div className='ag__qrm-container'>
      <BreadCrumbs items={items}/>
      <div className='ag__products-title'>Quality Risk Manager</div>
      {/** SECTION A */}
      <div className='ag__products-separator'>
        <div className='ag__product-info-box'>
          <div className='ag__product-infotitle'>{info?.sectionA?.valueA}</div>
          <div className='ag__product-infomsg'>{info?.sectionA?.valueB}</div>
          <div className='ag__product-infoitems'>
            {
              info?.sectionA?.items?.map((item, index) => {
                return (
                  <div key={index} className='ag__product-infoitem'>
                    <i className='fa fa-arrow-right'></i>{item}
                  </div>
                );
              })
            }
          </div>
        </div>
        <div className='ag__product-image-box'>
          <Lazyloading
            src={images[info?.sectionA?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION B */}
      <div className='ag__products-separator'>
        <div className='ag__product-info-box'>
          <div className='ag__product-infotitle'>
            {info?.sectionB?.title}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionB?.valueA}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionB?.valueB}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionB?.valueC}
          </div>
        </div>
        <div className='ag__product-image-box'>
          <Lazyloading
            src={images[info?.sectionB?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION C */}
      <div className='ag__products-separator'>
        <div className='ag__product-info-box'>
          <div className='ag__product-infotitle'>
            {info?.sectionC?.title}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionC?.valueA}
          </div>
          <div className='ag__product-infoitems'>
            {
              info?.sectionC?.items?.map((item, index) => {
                return (
                  <div key={index} className='ag__product-infoitem'>
                    <i className='fa fa-arrow-right'/>{item}
                  </div>
                );
              })
            }
          </div>

          <div className='ag__product-infomsg'>
            {info?.sectionC?.valueB}
          </div>
        </div>
        <div className='ag__product-image-box'>
          <Lazyloading
            src={images[info?.sectionC?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION D */}
      <div className='ag__products-separator'>
        <div className='ag__product-info-box'>
          <div className='ag__product-infotitle'>
            {info?.sectionD?.title}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionD?.valueA}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionD?.valueB}
          </div>
        </div>
        <div className='ag__product-image-box'>
          <Lazyloading
            src={images[info?.sectionD?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION E */}
      <div className='ag__products-separator'>
        <div className='ag__product-info-box'>
          <div className='ag__product-infotitle'>
            {info?.sectionE?.title}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionE?.valueA}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionE?.valueB}
          </div>
        </div>
        <div className='ag__product-image-box'>
          <Lazyloading
            src={images[info?.sectionE?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION F */}
      <div className='ag__products-separator'>
        <div className='ag__product-info-box'>
          <div className='ag__product-infotitle'>
            {info?.sectionF?.title}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionF?.valueA}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionF?.valueB}
          </div>
        </div>
        <div className='ag__product-image-box'>
          <Lazyloading
            src={images[info?.sectionF?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
      {/** SECTION G */}
      <div className='ag__products-separator'>
        <div className='ag__product-info-box'>
          <div className='ag__product-infotitle'>
            {info?.sectionG?.title}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionG?.valueA}
          </div>
          <div className='ag__product-infomsg'>
            {info?.sectionG?.valueB}
          </div>
        </div>
        <div className='ag__product-image-box'>
          <Lazyloading
            src={images[info?.sectionG?.key]}
            alt={'Loading...'}
            blurSrc={Ash2}
          />
        </div>
      </div>
    </div>
  );
};

export default QRM;
