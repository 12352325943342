import React, {useEffect, useState} from 'react';
import './index.css';

const Carousil = ({items}) => {
  // USESTATES
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    setInterval(() => {
      handleMoveForward();
    }, 5000);
  }, []);
  // HANDLERS
  const handleMoveForward = () => {
    setCurrentIndex((prev) => (prev === items?.length - 1) ? 0 : prev + 1);
  };
  const handleMoveBackward = () => {
    setCurrentIndex((prev) => (prev === 0) ? items?.length - 1 : prev - 1);
  };

  useEffect(() => {
    const blurredImageDivs = document.querySelectorAll('.blurred-img');
    blurredImageDivs.forEach((blurredImageDiv) => {
      const img = blurredImageDiv.querySelector('img');
      // eslint-disable-next-line require-jsdoc
      function loaded() {
        blurredImageDiv.classList.add('loaded');
      }
      if (img.complete) {
        loaded();
      } else {
        img.addEventListener('load', loaded);
      }
    });
  }, [currentIndex]);
  return (
    <div className='ag__carousel-container'>
      <div className='ag__carousel-subcontainer'
        style={{transform: `translateX(-${currentIndex * 100}%)`}}>
        {items.map((item, index) => (
          <div key={index} className='ag__carousel-box'>
            <div className="blurred-img"
              style={{backgroundImage: `url(${item.blur})`}}>
              <img src={item.img} alt='Loading' loading='lazy' />
            </div>
          </div>
        ))}
      </div>
      <div className='ag__carousel-leftarrow' onClick={handleMoveBackward}>
        <i className="fa fa-chevron-left"></i>
      </div>
      <div className='ag__carousel-rightarrow' onClick={handleMoveForward}>
        <i className="fa fa-chevron-right"></i>
      </div>
      <div className='ag__carousel-indicators'>
        {
          items?.map((item, index) => {
            return (
              <div key={index}
                className={`ag__carousel-indicator 
                ${index === currentIndex ?
                  'ag__carousel-indicator-active': ''}`}
              ></div>
            );
          })
        }
      </div>
    </div>
  );
};

export default Carousil;
