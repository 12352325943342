import React from 'react';
import './index.css';

const Divider = () => {
  return (
    <div className='ag__divider-box'>
      <div className='ag__divider'></div>
    </div>
  );
};

export default Divider;
